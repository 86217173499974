import { ecommerce } from '..';
import { getFeeds, getFeedsWithoutParams } from './getFeeds';
import { createFeed } from './createFeed';
import { updateFeed } from './updateFeed';
import { deleteFeed } from './deleteFeed';
import { feedDictionary } from './feedDictionary';

export const api = ecommerce;

export { getFeeds, getFeedsWithoutParams, createFeed, updateFeed, deleteFeed, feedDictionary };
