import { api } from '.';
import { FEED_RESOURCE } from '@/services/keys';

export function createFeed(newFeedParserProvider, clientId) {
  const partialUrl = api.createUrl({
    [FEED_RESOURCE]: undefined,
  });
  const feedParserToSend = newFeedParserProvider.toFormData();
  feedParserToSend.set('clientId', clientId);
  return api.create(partialUrl, feedParserToSend);
}
