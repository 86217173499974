<template>
  <div>
    <asterix-section :status="sectionStatus">
      <template #header-title>Feed Parser Provider</template>
      <template #header-right>
        <sun-button
          variant="pill"
          class="px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800"
          color="gray"
          @click="createFeedParser"
        >
          + Feed Parser Provider
        </sun-button>
      </template>
      <template #content>
        <sun-filter-layout
          class="mb-5"
          show-reset
          :filters-added="filterFind"
          :has-error="anyError"
          :filters-available="availableFilters"
          @change="changeFilters"
          @close="filtersResetErrors"
          @remove="filtersResetErrors"
          @reset="resetFilters"
        >
          <template #quickFilters>
            <div class="flex flex-wrap min-w-max">
              <div class="m-1">
                <sun-search-input
                  class-input="text-sm"
                  :value="filterQuick['name']"
                  @search="onQuickFiltersSearch($event, 'name')"
                />
              </div>
            </div>
          </template>
          <template #[`filter.name`]="{ filter, onSelect, isDuplicate }">
            <sun-input
              type="text"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
              @enter="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
          <template #[`filter.type`]="{ filter, onSelect, isDuplicate }">
            <sun-select
              :options="filter.options"
              close-on-select
              disable-selected-options
              :track-by="filter.trackBy"
              label="name"
              :value="filtersMakeFiltersForSelect(value)"
              class="w-24"
              add-hex-color="orange"
              class-input="pt-0 shadow-none rounded-none multiselect"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
        </sun-filter-layout>
        <sun-data-table
          :headers="headers"
          :content="items"
          :loading="isLoading"
          striped
          class="w-full"
          @sort="onSortTable"
        >
          <template #[`col.createdAt`]="{ item }">
            <sun-data-table-cell>
              <date-tooltip v-if="item.createdAt" class="inline-flex" :date="item.createdAt" />
            </sun-data-table-cell>
          </template>
          <template #[`col.type`]="{ item }">
            <sun-data-table-cell>
              {{ item.feedType.name }}
            </sun-data-table-cell>
          </template>

          <template #[`col.actions`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <table-action-menu :actions="actions" :item="item" :items="items" @click="onActionClick($event, item)" />
            </sun-data-table-cell>
          </template>

          <template #empty>
            <asterix-no-data class="bg-white" />
          </template>
        </sun-data-table>
        <sun-pagination-page
          :total-pages="totalPages"
          :total-items="totalItems"
          :current-page="currentPage"
          :value="itemsPerPage"
          :options-per-page="availablePageSizes"
          @changePage="goToPage({ page: $event })"
          @changePerPage="changeItemsPerPage"
        />
        <feed-parser-provider-modal
          v-if="showNewFeedParserModal"
          v-model="feedParserSelected"
          :is-editing="isEditing"
          :is-loading="isLoading"
          @submit="onModalSubmit"
          @cancel="closeModal"
        >
          <template #title>
            {{ creativeFormTitle }}
          </template>
        </feed-parser-provider-modal>
        <delete-modal :open="showDeleteModal" @cancel="showDeleteModal = false" @confirm="deleteFeedParser">
          <template #title> {{ deleteUserText }} </template>
          <template #description> {{ deleteUserConfirmText }} </template>
        </delete-modal>
      </template>
    </asterix-section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CONFIG from './config';
import AsterixSection from '@/components/templates/AsterixSection';
import DateTooltip from '@/components/atoms/DateTooltip/DateTooltip';
import FeedParserProvider from '@/entities/Core/FeedParserProvider';
import { createFeed, deleteFeed, getFeeds, updateFeed } from '@/services/modules/ecommerce/feed';
import { deepClone } from '@/utils/deepClone';
import { COMMON_TEXTS } from '@/i18n/commonTexts';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import { indexMixin } from '@/mixins/index/indexMixin';
import filtersMixin from '@/mixins/filters/filtersMixin';
import DeleteModal from '@/components/organisms/shared/DeleteModal';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';

export default {
  name: 'FeedParserProviderList',
  components: {
    AsterixSection,
    DateTooltip,
    DeleteModal,
    TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
    FeedParserProviderModal: () => import('@/components/organisms/modules/ecommerce/feed/FeedParserProviderModal.vue'),
  },
  mixins: [
    activeClientMixin,
    filtersMixin({
      filters: CONFIG.filters,
      filterQuick: {
        name: undefined,
      },
    }),
    indexMixin,
  ],
  data: () => ({
    headers: CONFIG.headers,
    actions: [{ name: 'Edit' }, { name: 'Delete' }],
    items: [],
    showNewFeedParserModal: false,
    showDeleteModal: false,
    feedParserSelected: new FeedParserProvider(),
    isEditing: false,
    deleteUserText: COMMON_TEXTS.en.names.feedParserProvider.actions.delete,
    deleteUserConfirmText: COMMON_TEXTS.en.names.feedParserProvider.messages.deleteConfirm,
    availablePageSizes: [20, 50, 100],
  }),
  computed: {
    creativeFormTitle() {
      return this.isEditing ? 'Edit Feed Parser Provider' : 'New Feed Parser Provider';
    },
  },
  async mounted() {
    // load filters before api request
    await this.filtersLoadAllfiltersOnMounted();

    await this.getTableItems();
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    async getTableItems() {
      this.isLoading = true;
      const { data, isCancel } = await this.getItemsFromAPI(getFeeds);
      this.items = data;
      if (!isCancel) {
        this.isLoading = false;
      }
    },
    onActionClick(event, item) {
      this.feedParserSelected = deepClone(item);
      if (event.name === 'Edit') {
        this.isEditing = true;
        this.showNewFeedParserModal = true;
      } else {
        this.showDeleteModal = true;
      }
    },
    createFeedParser() {
      this.showNewFeedParserModal = true;
    },
    async deleteFeedParser() {
      try {
        await deleteFeed(this.feedParserSelected.id);
        this.createToast(
          Toast.success('Feed deleted', `Feed ${this.feedParserSelected.name} was deleted successfully.`)
        );
        this.deleteFromCacheById(this.feedParserSelected.id);
        await this.getTableItems();
      } catch (e) {
        this.createToast(Toast.error('Error!', 'Feed not deleted.'));
      } finally {
        this.feedParserSelected = new FeedParserProvider();
        this.showDeleteModal = false;
      }
    },
    async onModalSubmit(event) {
      if (!event.valid) return;
      this.isLoading = true;
      try {
        if (this.isEditing) {
          await updateFeed(this.feedParserSelected);
        } else {
          await createFeed(this.feedParserSelected, this.activeClient.id);
        }
        this.createToast(Toast.success('Feed saved', `Feed ${this.feedParserSelected.name} was saved successfully.`));
        await this.getTableItems();
      } catch {
        this.createToast(Toast.error('Feed error', 'Feed not saved'));
      } finally {
        this.isLoading = false;
        this.closeModal();
      }
    },
    closeModal() {
      this.isEditing = false;
      this.showNewFeedParserModal = false;
      this.feedParserSelected = new FeedParserProvider();
    },
    changePage(page) {
      this.goToPage({ page });
    },
    async changeFilters(filters) {
      await this.updateFilters(filters);
      await this.getTableItems();
    },
    async updateFilters(filters = []) {
      this.filterFind = filters;
      await this.filtersUpdateFiltersOnStoreAndURL();
    },
  },
};
</script>
<style scoped>
::v-deep .asterix-modal > div.bg-white.overflow-hidden {
  min-width: 66vw;
}
</style>
