var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asterix-section", {
        attrs: { status: _vm.sectionStatus },
        scopedSlots: _vm._u([
          {
            key: "header-title",
            fn: function () {
              return [_vm._v("Feed Parser Provider")]
            },
            proxy: true,
          },
          {
            key: "header-right",
            fn: function () {
              return [
                _c(
                  "sun-button",
                  {
                    staticClass:
                      "px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800",
                    attrs: { variant: "pill", color: "gray" },
                    on: { click: _vm.createFeedParser },
                  },
                  [_vm._v(" + Feed Parser Provider ")]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("sun-filter-layout", {
                  staticClass: "mb-5",
                  attrs: {
                    "show-reset": "",
                    "filters-added": _vm.filterFind,
                    "has-error": _vm.anyError,
                    "filters-available": _vm.availableFilters,
                  },
                  on: {
                    change: _vm.changeFilters,
                    close: _vm.filtersResetErrors,
                    remove: _vm.filtersResetErrors,
                    reset: _vm.resetFilters,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "quickFilters",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "flex flex-wrap min-w-max" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "m-1" },
                                  [
                                    _c("sun-search-input", {
                                      attrs: {
                                        "class-input": "text-sm",
                                        value: _vm.filterQuick["name"],
                                      },
                                      on: {
                                        search: function ($event) {
                                          return _vm.onQuickFiltersSearch(
                                            $event,
                                            "name"
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: `filter.name`,
                        fn: function ({ filter, onSelect, isDuplicate }) {
                          return [
                            _c("sun-input", {
                              attrs: {
                                type: "text",
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                                enter: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: `filter.type`,
                        fn: function ({ filter, onSelect, isDuplicate }) {
                          return [
                            _c("sun-select", {
                              staticClass: "w-24",
                              attrs: {
                                options: filter.options,
                                "close-on-select": "",
                                "disable-selected-options": "",
                                "track-by": filter.trackBy,
                                label: "name",
                                value: _vm.filtersMakeFiltersForSelect(
                                  _vm.value
                                ),
                                "add-hex-color": "orange",
                                "class-input":
                                  "pt-0 shadow-none rounded-none multiselect",
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("sun-data-table", {
                  staticClass: "w-full",
                  attrs: {
                    headers: _vm.headers,
                    content: _vm.items,
                    loading: _vm.isLoading,
                    striped: "",
                  },
                  on: { sort: _vm.onSortTable },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: `col.createdAt`,
                        fn: function ({ item }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              [
                                item.createdAt
                                  ? _c("date-tooltip", {
                                      staticClass: "inline-flex",
                                      attrs: { date: item.createdAt },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.type`,
                        fn: function ({ item }) {
                          return [
                            _c("sun-data-table-cell", [
                              _vm._v(" " + _vm._s(item.feedType.name) + " "),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.actions`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("table-action-menu", {
                                  attrs: {
                                    actions: _vm.actions,
                                    item: item,
                                    items: _vm.items,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onActionClick($event, item)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "empty",
                        fn: function () {
                          return [
                            _c("asterix-no-data", { staticClass: "bg-white" }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("sun-pagination-page", {
                  attrs: {
                    "total-pages": _vm.totalPages,
                    "total-items": _vm.totalItems,
                    "current-page": _vm.currentPage,
                    value: _vm.itemsPerPage,
                    "options-per-page": _vm.availablePageSizes,
                  },
                  on: {
                    changePage: function ($event) {
                      return _vm.goToPage({ page: $event })
                    },
                    changePerPage: _vm.changeItemsPerPage,
                  },
                }),
                _vm.showNewFeedParserModal
                  ? _c("feed-parser-provider-modal", {
                      attrs: {
                        "is-editing": _vm.isEditing,
                        "is-loading": _vm.isLoading,
                      },
                      on: { submit: _vm.onModalSubmit, cancel: _vm.closeModal },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _vm._v(
                                  " " + _vm._s(_vm.creativeFormTitle) + " "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3287360029
                      ),
                      model: {
                        value: _vm.feedParserSelected,
                        callback: function ($$v) {
                          _vm.feedParserSelected = $$v
                        },
                        expression: "feedParserSelected",
                      },
                    })
                  : _vm._e(),
                _c("delete-modal", {
                  attrs: { open: _vm.showDeleteModal },
                  on: {
                    cancel: function ($event) {
                      _vm.showDeleteModal = false
                    },
                    confirm: _vm.deleteFeedParser,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [_vm._v(" " + _vm._s(_vm.deleteUserText) + " ")]
                      },
                      proxy: true,
                    },
                    {
                      key: "description",
                      fn: function () {
                        return [
                          _vm._v(" " + _vm._s(_vm.deleteUserConfirmText) + " "),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }