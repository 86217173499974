import { api } from '.';
import { FEED_RESOURCE, DICTIONARY_RESOURCE } from '@/services/keys';
import FeedParserProvider from '@/entities/Core/FeedParserProvider';

export async function feedDictionary(source) {
  const bodyData = new FormData();
  if (!source?.url) {
    bodyData.set('file', source);
  } else {
    bodyData.set('url', source.url);
  }
  const partialUrl = api.createUrl({
    [FEED_RESOURCE]: undefined,
    [DICTIONARY_RESOURCE]: undefined,
  });
  const { data } = await api.create(partialUrl, bodyData);

  data.dictionary = FeedParserProvider.parseDictionary(data.dictionary);

  return { data };
}
