import Column from '@/model/shared/Column';
import { ROLES } from '@/model/shared/roles';
import FILTERS_CONFIG from '@/model/modules/ecommerce/filtersConfig';

const CONFIG = {
  headers: [
    new Column('NAME', 'name').setAllowedRoles(ROLES.ADMIN.id).asSort().setClass('font-bold truncate'),
    new Column('TYPE', 'type').setAllowedRoles(ROLES.ADMIN.id).asSort(),
    new Column('CREATE DATE', 'createdAt').setAllowedRoles(ROLES.ADMIN.id).asSort(),
    new Column('ACTIONS', 'actions').setAllowedRoles(ROLES.ADMIN.id).setClass('w-4'),
  ],
  filters: [FILTERS_CONFIG.NAME, FILTERS_CONFIG.FEED_TYPE],
};
export default CONFIG;
