export const COMMON_TEXTS = {
  en: {
    rightsReservedText: 'SunMedia All rights reserved.',
    accesDenied: 'Acces denied',
    noAccesMessage: 'Cannot access to the app, ask to admin about it.',
    withoutPermissionText: 'Without permission to carry out this action',
    pageNotFoundText: 'Page not found',
    goHomeText: 'Go Home',
    profile: {
      info: 'This is your profile page. You can see your information and edit it.',
      contexts: 'Contexts',
      form: {
        title: 'My account',
        saveButton: 'Save Profile',
      },
    },
    actions: {
      delete: 'Delete',
      save: 'Save',
      cancel: 'Cancel',
      logout: 'Logout',
    },
    names: {
      advertiser: {
        singular: 'Advertiser',
        plural: 'Advertisers',
        actions: {
          delete: 'Delete advertiser',
        },
        messages: {
          deleteConfirm: 'Are you sure you want to delete this advertiser?',
        },
      },
      client: {
        singular: 'Client',
        plural: 'Clients',
        actions: {
          delete: 'Delete client',
        },
        messages: {
          deleteConfirm: 'Are you sure you want to delete this client?',
        },
      },
      feedParserProvider: {
        singular: 'Feed parser provider',
        plural: 'Feed parser providers',
        actions: {
          delete: 'Delete feed parser provider',
        },
        messages: {
          deleteConfirm: 'Are you sure you want to delete this feed parser provider?',
        },
      },
      user: {
        singular: 'User',
        plural: 'Users',
        actions: {
          delete: 'Delete user',
        },
        messages: {
          deleteConfirm: 'Are you sure you want to delete this user?',
        },
      },
      platform: {
        singular: 'Platform',
        plural: 'Platforms',
        actions: {
          delete: 'Delete platform',
        },
        messages: {
          deleteConfirm: 'Are you sure you want to delete this platform?',
        },
      },
    },
  },
};
