import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { api } from '.';
import { FEED_RESOURCE } from '@/services/keys';
import coreEntityParser from '@/entities/Core/CoreEntityParser';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

export async function getFeeds(params = new QueryParamsBuilder(), clientId) {
  const partialUrl = api.createUrl({
    [FEED_RESOURCE]: undefined,
  });
  params.addFilter('client.id', clientId);
  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(coreEntityParser.parse(data), data.meta).build();
}

export async function getFeedsWithoutParams(params = new QueryParamsBuilder(), clientId) {
  const partialUrl = api.createUrl({
    [FEED_RESOURCE]: undefined,
  });
  params.addFilter('client.id', clientId);
  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(coreEntityParser.parse(data), data.meta).build();
}
